.modal {
	.modal-title {
		color: var(--dark);
		margin-top: 0em;
	}
	.modal-content {
		color: var(--dark);
	}
	.modal-header {
		border-bottom: none;
	}
	.modal-footer {
		border-top: none;

		button {
			background-color: #35454B;
			border-color: #35454B;
			padding: 0.35rem 2rem 
		}
	}
}