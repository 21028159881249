/*
 * Table of contents:
 * 
 * from line 14 - Primary
 * from line 87 - Preloader style
 * from line 116 - Cursor
 * from line 223 - Primary style
 * from line 438 - Navigation
 * from line 843 - Home/Work/Project pages
 * from line 1623 - Studio page
 * from line 1712 - Blog/Post page
 * from line 2131 - Contact
 * from line 2558 - Media, for smaller screens
 *
 */

/* #Primary
================================================== */

body{
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.7;
	color: #fafafa;
	background-color: #000000;
	overflow-x: hidden;
}

p {
	line-height: 1.5em;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
	font-family: "Yanone Kaffeesatz", sans-serif;
	font-weight: 300;
	margin-bottom: 0;
	line-height: 1.35;
	color: #fff;
}
h1, .h1 {
	font-size: 3.2rem;
	font-weight: 600;
}
h2, .h2 {
	font-size: 3.2rem;
}
h3, .h3 {
	font-size: 2.6rem;
	font-weight: 400;
}
h4, .h4 {
	font-size: 1.9rem;
	font-weight: 400;
}
h5, .h5 {
	font-size: 1.35rem;
	font-weight: 400;
}
h6, .h6 {
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 1rem;
}
::selection {
	color: #fff;
}
::-moz-selection {
	color: #fff;
}
::selection {
	background-color: #000;
}
::-moz-selection {
	background-color: #000;
}
mark{
	color:#212121;
}
.lead {
	font-size: 1.15rem;
	font-weight: 300;
}
.lead-big {
	font-size: 1.55rem;
	line-height: 1.6;
	font-weight: 300;
}

.faqs .section a {

	color: #EFEFEF;
	position: relative;
	// display: inline-block;

	&:hover:after {
		width: 102%;
		// margin: 0 0;
		bottom: -2px;
		transition: width 0.3s, margin 0.3s, bottom 0.5s;
	}

	&:after {
		transition: width 0.3s, margin 0.3s, bottom 0.5s;
		// margin: 0 51%;
		position: absolute;
		display: block;
		content: ' ';
		width: 0;
		height: 2px;
		bottom: -2px;
		left: -1%;
		right: -1%;
	 	background-color: #083043;
	 	background-image: linear-gradient(315deg, #083043 0%, #F1F1F1 74%);


	}
}
.faqs b {
	font-weight: bold;
}
.faqs h3, h5 {
	margin-top: 1.5em;
}

/* #Preloader style
================================================== */

.animsition-loading,
.animsition-loading:after {
	width: 36px;
	height: 36px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -18px;
	margin-left: -18px;
	border-radius: 50%;
	z-index: 100;
}
.animsition-loading {
	opacity: .2;
	background-color: transparent;
	border:none;
	background-color: #fff;
	animation: loaderAnim 0.7s linear infinite alternate forwards;
}
@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

/* #Cursor
================================================== */

.cursor,
.cursor2,
.cursor3{
	position: fixed;
	border-radius: 50%;	
	transform: translateX(-50%) translateY(-50%);
	pointer-events: none;
	left: -100px;
	top: 50%
}
.cursor{
	background-color: #fff;
	height: 0;
	width: 0;
	z-index: 99999;
}
.cursor2,.cursor3{
	height: 36px;
	width: 36px;
	z-index:99998;
	-webkit-transition:all 0.3s ease-out;
	transition:all 0.3s ease-out
}
.cursor2.hover,
.cursor3.hover{
	-webkit-transform:scale(2) translateX(-25%) translateY(-25%);
	transform:scale(2) translateX(-25%) translateY(-25%);
	border:none
}
.cursor2{
	border: 2px solid #fff;
	box-shadow: 0 0 22px rgba(255, 255, 255, 0.6);
}
.cursor2.hover{
	background: rgba(255,255,255,.1);
	box-shadow: 0 0 12px rgba(255, 255, 255, 0.2);
}
.scale-up .cursor2,
.scale-up .cursor3{
	opacity: 0;
}

.cursor-dark .cursor2,
.cursor-dark .cursor3{
	border: 2px solid #4b4d64;
	box-shadow: 0 0 14px rgba(75, 77, 100, 0.3);
}
.cursor-dark .cursor2.hover,
.cursor-dark .cursor3.hover{
	background: rgba(75, 77, 100, 0.3);
	border: none;
	box-shadow: 0 0 14px rgba(75, 77, 100, 0.2);
}


.video-cursor .cursor2.hover,
.video-cursor .cursor3.hover{
	opacity: 0;
}


@media screen and (max-width: 1200px){
	.cursor,.cursor2,.cursor3{
		display: none
	}
}
	
a {
	cursor: pointer;
}
a:hover {
	text-decoration: none;
}

/* #back to top arrow
================================================== */

.scroll-to-top{
	position: fixed;
	bottom: 210px;
	right: 36px;
	opacity: 0;
	visibility: hidden;
	width: 10px;
	height: 40px;
	z-index: 251;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: 10px 40px;
	cursor: pointer;
	background-image: url('../img/arrow-up.png');
	transform: translateY(-15px);
	-webkit-transition: all 250ms linear;
	transition: all 250ms linear; 
}
.scroll-to-top:hover{
	bottom: 212px;
}
.scroll-to-top.active-arrow{
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}

/* #Primary style
================================================== */

.section {
    position: relative;
	width: 100%;
	display: block;
}
.relative {
    position: relative;
}
.over-hide{
	overflow: hidden;
}
.full-height {
	height: 100vh;
}
.hero-full-height {
	height: calc(100vh - 140px);
}
.half-height {
	height: 50vh;
}
.big-55-height {
	height: 55vh;
}
.big-60-height {
	height: 60vh;
}
.big-65-height {
	height: 65vh;
}
.big-70-height {
	height: 70vh;
}
.big-75-height {
	height: 75vh;
}
.big-80-height {
	height: 80vh;
}
.max-width-80 {
	max-width: 80%;
}
.background-white {
	background-color: #ffffff;
}
.background-grey {
	background-color: #fbfbfb;
}
.background-grey-1 {
	background-color: #f9f9f9;
}
.background-dark {
	background-color: #101010;
}
.background-dark-1 {
	background-color: #242424;
}
.background-dark-2 {
	background-color: #1f2029;
}
.background-dark-3,
.background-dark-4 {
	background-color: #000000;
}
.background-black {
	background-color: #000000;
}
.color-black {
	color: #000000;
}
.color-white {
	color: #ffffff;
}
.color-grey {
	color: #e1e1e1;
}
.img-wrap  {
	position:relative;
	width:100%;
	display:block;
}
.img-wrap img {
	width:100%;
	height: auto;
	display:block;
}
.z-bigger  {
	z-index:10;
}
.z-bigger-20  {
	z-index:20;
}
.z-bigger-30  {
	z-index:30;
}
.z-bigger-40  {
	z-index:40;
}
.z-bigger-50  {
	z-index:50;
}
.container.z-bigger  {
	z-index: 50;
}
.z-too-big  {
	z-index:11000;
}
.padding-page-top {
	padding-top: 300px;
}
.padding-top-bottom {
	padding-top: 80px;
	padding-bottom: 80px;
}
.padding-top {
	padding-top: 100px;
}
.padding-bottom {
	padding-bottom: 100px;
}
.padding-top-bottom-big {
	padding-top: 140px;
	padding-bottom: 140px;
}
.padding-top-big {
	padding-top: 140px;
}
.padding-bottom-big {
	padding-bottom: 20px;
}
.padding-top-bottom-small {
	padding-top: 70px;
	padding-bottom: 70px;
}
.padding-top-small {
	padding-top: 70px;
}
.padding-tb-small-xs {
	padding-top: 0.5rem;
	padding-bottom: 2rem;
}
.padding-bottom-small {
	padding-bottom: 70px;
}
.padding-top-bottom-smaller {
	padding-top: 50px;
	padding-bottom: 50px;
}
.padding-top-smaller {
	padding-top: 50px;
}
.padding-bottom-smaller {
	padding-bottom: 50px;
}
.page-title h1{
	position: relative;
	font-size: 3rem;
	color: #fff;
	font-weight: 900;
	line-height: 1.25;
	z-index: 2;
}
.page-title p{
	position: relative;
	font-size: 24px;
	font-weight: 500;
	z-index: 2;
}
.page-title-small p{
	position: relative;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 1px;
	padding-left: 95px;
	z-index: 2;
}
.page-title-small p:before{
	position: absolute;
	content: '';
	top:50%;
	left: 35px;
	width: 40px;
	height: 1px;
	background-color: #616481;
}
.shadow-title{
	position: fixed;
	top: 0;
	margin-top: 80px;
	left: 20px;
	width: 100%;
	height: 300%;
	text-align: left;
	font-family: 'Muli', sans-serif;
	-webkit-writing-mode: vertical-lr;
	writing-mode: vertical-lr;
	font-size: 14vw;
	line-height: 1;
	color: rgba(200,200,200,.1);
	background: linear-gradient(90deg, rgba(200,200,200,0), rgba(200,200,200,0.65));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 900;
	z-index: 1;
}


/* #Navigation
================================================== */
 
.header{
    position: fixed;
	width:100%;
	top:0;
	left:0;
	z-index:100;
} 
.header-wrapper{
    position: relative;
    width: calc(100% - 50px);
    margin-left: 25px;
} 
.logo-wrap {
	position: absolute;
	display:block;
	left:0;
	top: 33px;
	cursor: pointer;
}
.logo-wrap a {
	cursor: pointer;
}
.logo-wrap img{
	height: 24px;
	width:auto;
	display:block;
	cursor: pointer;
}
.cd-header {
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(20, 21, 26, 0.96);
	width: 100%;
	z-index: 100;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	height: 100px;
	background: transparent;
	box-shadow: none;
	-webkit-transition: all 300ms linear;
	-moz-transition: all 300ms linear;
	-o-transition: all 300ms linear;
	-ms-transition: all 300ms linear;
	transition: all 300ms linear;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.cd-header.is-fixed {
	position: fixed;
	top: -100px;
	background-color: rgba(0, 0, 0, 1);
	-webkit-transition: -webkit-transform 0.3s;
	-moz-transition: -moz-transform 0.3s;
	transition: transform 0.3s;
}
.cd-header.is-visible {
	-webkit-transform: translate3d(0, 100%, 0);
	-moz-transform: translate3d(0, 100%, 0);
	-ms-transform: translate3d(0, 100%, 0);
	-o-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}
.cd-header.menu-is-open {
	background-color: rgba(21, 21, 21, 0);
}


.nav-but-wrap{
	position: relative;
	display: inline-block;
	float: right;
	padding-left: 15px;
	padding-top: 20px;
	margin-top: 15px;
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.menu-icon {
	height: 30px;
	width: 30px;
	position: relative;
	z-index: 2;
	cursor: pointer;
	display: block;
}
.menu-icon__line {
	height: 2px;
	width: 30px;
	display: block;
	background-color: #fff;
	margin-bottom: 7px;
	cursor: pointer;
	-webkit-transition: background-color .5s ease, -webkit-transform .2s ease;
	transition: background-color .5s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, background-color .5s ease;
	transition: transform .2s ease, background-color .5s ease, -webkit-transform .2s ease;
}
.menu-icon__line-left {
	width: 16.5px;
	-webkit-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-o-transition: all 200ms linear;
	-ms-transition: all 200ms linear;
	transition: all 200ms linear;
}
.menu-icon__line-right {
	width: 16.5px;
	float: right;
	-webkit-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-o-transition: all 200ms linear;
	-ms-transition: all 200ms linear;
	transition: all 200ms linear;
}
.menu-icon:hover .menu-icon__line-left,
.menu-icon:hover .menu-icon__line-right {
	width: 30px;
}

.nav {
	position: fixed;
	z-index: 98;
}
.nav:before, .nav:after {
	content: "";
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 1);
	z-index: -1;
	-webkit-transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
	transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
	transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
	transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
	-webkit-transform: translateX(0%) translateY(-100%);
          transform: translateX(0%) translateY(-100%);
}
.nav:after {
	background: rgba(0,0,0,1);
	-webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.nav:before {
	-webkit-transition-delay: .2s;
          transition-delay: .2s;
}
.nav__content {
	position: fixed;
	visibility: hidden;
	top: 50%;
	margin-top: 40px;
	-webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
	width: 100%;
	text-align: center;
	font-size: 6px;
}
.curent-page-name-shadow {
	position: absolute;
	top: 55%;
	left: 0%;
	width: 100%;
	line-height: 1;
	text-align: center;
	-webkit-transform: translateY(-50%);
          transform: translateY(-50%);
	font-family: "Yanone Kaffeesatz", sans-serif;
	font-size: 20vw;
	margin-top: 40px;
	font-weight: 900;
	color: #fff;
	opacity: 0;
	z-index: 1;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.nav__list {
	position: relative;
	padding: 0;
	margin: 0;
	z-index: 2;
}
.nav__list-item {
	position: relative;
	display: block;
	-webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
	opacity: 0;
	text-align: center;
	color: #fff;
	overflow: hidden; 
	font-family: "Yanone Kaffeesatz", sans-serif;
	font-size: 7vh;
	font-weight: 700;
	line-height: 1.05;
	letter-spacing: 3px;
	-webkit-transform: translate(0%, 100%);
          transform: translate(0%, 100%);
	-webkit-transition: opacity .2s ease, -webkit-transform .3s ease;
	transition: opacity .2s ease, -webkit-transform .3s ease;
	transition: opacity .2s ease, transform .3s ease;
	transition: opacity .2s ease, transform .3s ease, -webkit-transform .3s ease;
	margin-top: 0;
	margin-bottom: 0;
}
.nav__list-item a{ 
	position: relative;
	text-decoration: none;
	color: rgba(255,255,255,0.6);
	overflow: hidden; 
	cursor: pointer;
	padding-left: 5px;
	padding-right: 5px;
	z-index: 2;
	display: inline-block;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.nav__list-item a:after{ 
	position: absolute;
	content: '';
	bottom: 2px;
	left: 0;
	width: 100%;
	height: 0;
	opacity: 0;
	margin-top: 3px;
	background-color: rgba(255,255,255,0.2);
	z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.nav__list-item a:hover:after{ 
	height: 20px;
	opacity: 1;
}
.nav__list-item a:hover{
	color: rgba(255,255,255,1);
}
.nav__list-item.active-nav a{
	color: rgba(255,255,255,1);
}
.nav__list-item.active-nav a:after{ 
	height: 20px;
	opacity: 1;
}
body.nav-active {
	/*overflow: hidden;*/
}
body.nav-active .nav__content {
	visibility: visible;
}
body.nav-active .cd-header.is-fixed {
	-webkit-transform: translate3d(0, 100%, 0);
	-moz-transform: translate3d(0, 100%, 0);
	-ms-transform: translate3d(0, 100%, 0);
	-o-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}
body.nav-active .cd-header.is-visible {
}
body.nav-active .menu-icon__line {
	background-color: #fff;
	-webkit-transform: translate(0px, 0px) rotate(-45deg);
          transform: translate(0px, 0px) rotate(-45deg);
}
body.nav-active .menu-icon__line-left {
	width: 15px;
	-webkit-transform: translate(2px, 4px) rotate(45deg);
          transform: translate(2px, 4px) rotate(45deg);
}
body.nav-active .menu-icon__line-right {
	width: 15px;
	float: right;
	-webkit-transform: translate(-3px, -3.5px) rotate(45deg);
          transform: translate(-3px, -3.5px) rotate(45deg);
}
body.nav-active .menu-icon:hover .menu-icon__line-left,
body.nav-active .menu-icon:hover .menu-icon__line-right {
	width: 15px;
}
body.nav-active .nav {
	visibility: visible;
}
body.nav-active .nav:before, body.nav-active .nav:after {
	-webkit-transform: translateX(0%) translateY(0%);
          transform: translateX(0%) translateY(0%);
}
body.nav-active .nav:after {
	-webkit-transition-delay: .1s;
          transition-delay: .1s;
}
body.nav-active .nav:before {
	-webkit-transition-delay: 0s;
          transition-delay: 0s;
}
body.nav-active .curent-page-name-shadow {
	margin-top: -25px;
	opacity: 0.08;
	-webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
body.nav-active .nav__list-item {
	opacity: 1;
	-webkit-transform: translateX(0%);
          transform: translateX(0%);
	-webkit-transition: opacity .3s ease, color .3s ease, -webkit-transform .3s ease;
	transition: opacity .3s ease, color .3s ease, -webkit-transform .3s ease;
	transition: opacity .3s ease, transform .3s ease, color .3s ease;
	transition: opacity .3s ease, transform .3s ease, color .3s ease, -webkit-transform .3s ease;
}
body.nav-active .nav__list-item:nth-child(0) {
	-webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
body.nav-active .nav__list-item:nth-child(1) {
	-webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
body.nav-active .nav__list-item:nth-child(2) {
	-webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}
body.nav-active .nav__list-item:nth-child(3) {
	-webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}
body.nav-active .nav__list-item:nth-child(4) {
	-webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}
body.nav-active .nav__list-item:nth-child(5) {
	-webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}
body.nav-active .nav__list-item:nth-child(6) {
	-webkit-transition-delay: 1s;
          transition-delay: 1s;
}
body.nav-active .nav__list-item:nth-child(7) {
	-webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
body.nav-active .nav__list-item:nth-child(8) {
	-webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}
body.nav-active .nav__list-item:nth-child(9) {
	-webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
body.nav-active .nav__list-item:nth-child(10) {
	-webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
.nav__list .sub-links{ 
	position: relative;
	padding: 0;
	margin: 0;
	margin-bottom: 20px;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.nav__list .sub-links li{ 
	padding: 0;
	margin: 0 auto;
	text-align: center;
	margin-top: 7px;
	margin-bottom: 7px;
	display: block;
}
.nav__list .sub-links li:first-child{ 
	margin-top: 0;
}
.nav__list .sub-links li a{ 
	color: rgba(255,255,255,.6);
	overflow: hidden;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;
	letter-spacing: 2px;
	padding: 0;
	margin: 0 auto;
	text-align: center;
	max-width: 250px;
	display: block;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.nav__list .sub-links li a:after{ 
	display: none;
}
.nav__list .sub-links li a:hover{ 
	color: rgba(255,255,255,1);
}
.nav__list .sub-links li.active-nav a{ 
	color: rgba(255,255,255,1);
}

/* #Home Slider
================================================== */
 
.swiper-wrapper{
	position: relative;
	padding: 0;
	margin: 0;
	min-height: 95vh;
	list-style: none;
	z-index: 3;
	cursor: grab;
}
.swiper-wrapper:active,
.swiper-wrapper:focus{
	cursor: grabbing;
	cursor: url('../img/grabbing.png') 28 15,grabbing;
}
.swiper-slide{
	position: relative;
	padding: 0 1.5rem;
	margin: 0;
}
.swiper-slide {
	width: auto;
	height:  auto;
	z-index: 3;
	cursor: grab;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: all 250ms linear;
	/*float: left;*/
    /*height: 100%;*/
    /*overflow-y: scroll;*/
    // padding: calc(50vh - 250px) 0 2em;
    padding: 1em 0 2em;
}

.swiper-slide[data-hash="TwitterVerification"] {
    padding: calc(50vh - 250px) 0 2em;
}
.swiper-slide[data-hash="CreateNFP"] {
    padding: calc(50vh - 350px) 0 2em;
}
.swiper-slide[data-hash="Loading"] {
    padding: calc(50vh - 350px) 0 2em;
}
.swiper-slide[data-hash="NFPMinted"] {
    padding: calc(50vh - 350px) 0 2em;
}


.swiper-
.swiper-slide > * {
	padding-bottom: 0.5rem;
}
.swiper-slide:active,
.swiper-slide:focus{
	cursor: grabbing;
	cursor: url('../img/grabbing.png') 28 15,grabbing;
}

.swiper-slide.bottom-name{
	-webkit-box-align: end;
	-ms-flex-align: end;
	-webkit-align-items: end;
	align-items: flex-end;
}

.swiper-slide a{
	cursor: pointer;
	margin: 0 100px;
}
.swiper-slide.bottom-name a{
	cursor: pointer;
	margin: 0;
}
.swiper-slide h1{
	position: relative; 
	font-size: 60px;
	letter-spacing: 3px;
	line-height: 1;
	font-weight: 800;
	text-align: center;
	padding: 0 5px;
	cursor: pointer;
	width: 100%;
	z-index: 3;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}

.swiper-slide.bottom-name h1{
	padding: 20px 100px;
	font-weight: 900;
	border-top: 1px solid rgba(255,255,255,0.2);
	border-right: 1px solid rgba(255,255,255,0.2);
	-webkit-text-stroke: 2px #fff;
	text-stroke: 2px #fff;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
}
.swiper-slide.bottom-name:first-child h1{
	border-left: 1px solid rgba(255,255,255,0.2);
}
.swiper-slide.bottom-name h1:before{
	background-color: rgba(255,255,255,.1);
}
.case-study-name.bottom-name.active h1:before{
	opacity: 1;
	height: 100%;
}
.case-study-name.bottom-name.active h1{
	opacity: 1;
	-webkit-text-stroke: 2px transparent;
	text-stroke: 2px transparent;
	-webkit-text-fill-color: #fff;
	text-fill-color: #fff;
	color: #fff;
}
.swiper-slide h1:before{
/*	position: absolute; 
	content: '';
	left: 0;
	height: 0;
	bottom: 0px;
	background-color: rgba(255,255,255,.5);
	cursor: pointer;
	width: 100%;
	z-index: 2;
	opacity: 0;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; */
}
/* Scrollbar */
.swiper-scrollbar {
	border-radius: 0;
	position: relative;
	-ms-touch-action: none;
	background: rgba(255, 255, 255, 0.2);
}
.swiper-container-horizontal > .swiper-scrollbar {
	position: absolute;
	left: 30%;
	right: 30%;
	bottom: 35px;
	z-index: 50;
	height: 1px;
	width: 40%;
}
.swiper-container-horizontal > .swiper-scrollbar.bottom-name {
	bottom: 140px;
}
.swiper-scrollbar-drag {
	height: 19px;
	border-radius: 50%;
	position: relative;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1); 
	left: 0;
	top: -9px;
	cursor: pointer;
}
.swiper-scrollbar-drag:active,
.swiper-scrollbar-drag:focus{
	cursor: grabbing;
	box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.1); 
}

.case-study-name h1{
	opacity: 0.6;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear; 
}
.case-study-name h1:hover{
	opacity: 1;
}
.case-study-name.active h1{
	opacity: 1;
}
.case-study-name.active h1:before{
	opacity: 1;
	height: 20px;
}
.case-study-images {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin:0;
	z-index: 1;
}	
.case-study-images li {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	list-style:none;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}	
.case-study-images li .img-hero-background{
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position:center center;
	background-repeat:no-repeat;
	top: 0;
	left: 0;
	overflow: hidden;
	list-style:none;
	opacity: 0;
	z-index: 5;	
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.case-study-images li .img-hero-background.no-blur{
	-webkit-clip-path: none;
	clip-path: none;
}
.case-study-images li.show .img-hero-background.no-blur {
	opacity: 0.45;
} 
.case-study-images li h2{
	position: absolute;
	width: 300%;
	text-align: center;
	font-size: 16vw;
	line-height: 0.8;
	padding-bottom: 25px;
	color: rgba(200,200,200,.1);
	background: linear-gradient(rgba(75, 77, 100,0.8), rgba(75, 77, 100,0));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 900;
	bottom: 0;
	left: -100%;
	list-style:none;
	opacity: 0;
	z-index: 7;
	-webkit-transform: translateY(-30px);
	transform: translateY(-30px);	
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.case-study-images li h2.no-blur-image{
	color: rgba(200,200,200,.1);
	background: linear-gradient(rgba(75, 77, 100,0.5), rgba(75, 77, 100,0));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	mix-blend-mode: none;
}
.case-study-images li h2.bottom-name{
	text-align: center;
	font-size: 14vw;
	line-height: 0.8;
	color: #353746;
	background: none;
	-webkit-background-clip: none;
	-webkit-text-fill-color: #353746;
	bottom: auto;
	top: 50%;
	margin-top: -20px;
	mix-blend-mode: screen;		
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);	
	-webkit-transition: all 400ms linear;
	transition: all 400ms linear; 
}
.case-study-images li.show h2.bottom-name{	
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	margin-top: -40px;	
}
.case-study-images li h2.fullscreen-image{
	color: #353746;
}
.case-study-images li h2.fullscreen-image.no-blur{
	background: none;
	-webkit-background-clip: #353746;
	-webkit-text-fill-color: #35374680;
	color: #35374680;
	mix-blend-mode: screen;
}
.case-study-images li.show h2 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.case-study-images li .img-hero-background-over{
	position: absolute;
	width: 60%;
	height: 60%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position:center center;
	background-repeat:no-repeat;
	top: 20%;
	left: 20%;
	overflow: hidden;
	list-style:none;
	opacity: 0;
	z-index: 8;
	box-shadow: 0 0 20px rgba(0,0,0,.3);
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.case-study-images li .img-hero-background-over:after{
	position: absolute; 
	content: '';
	left: 0;
	height: 100%;
	top: 0px;
	background-color: #111;
	width: 100%;
	z-index: 9;
	opacity: 0.3;
}
.case-study-images li.show .img-hero-background {
	opacity: 0.5;
} 
.case-study-images li.show .img-hero-background-over {
	opacity: 1;
}
.case-study-images li .hero-number-fixed{
	position: absolute;
	bottom: 70px;
	left: 50%;
	width: 40px;
	text-align: center;
	display: block;
	letter-spacing: 2px;
	list-style:none;
	font-size: 13px;
	margin-left: 30px;
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	line-height: 1;
	color: #fff;
	z-index: 6;
}
.case-study-images li .hero-number-fixed.bottom-name{
	bottom: 170px;
}
.case-study-images li .hero-number.bottom-name{
	bottom: 170px;
}
.case-study-images li .hero-number-fixed:before{
	position: absolute;
	content: '';
	top: 50%;
	left: -60px;
	width: 60px;
	height: 1px;
	z-index: 1;
	background-color: rgba(255,255,255,.6);
} 
.case-study-images li .hero-number{
	position: absolute;
	bottom: 70px;
	left: 50%;
	width: 40px;
	margin-left: -70px;
	text-align: center;
	display: block;
	letter-spacing: 2px;
	list-style:none;
	opacity: 0;
	font-size: 13px;
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	line-height: 1;
	color: #fff;
	z-index: 6;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.case-study-images li.show .hero-number {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
} 

/* #Home/Work Slice Reveal
================================================== */

.scroll-img {
	width: 800px;
	max-width: 100%;
	min-width: 200px;
	height: 40vh;
	min-height: 250px;
	background-size: cover;
	overflow: hidden;
}
.uncover__img {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
}
.uncover__slices {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
}
.uncover__slices--vertical {
	flex-direction: row;
}
.uncover__slices--horizontal {
	flex-direction: column;
}
.uncover__slices--horizontal-double {
	display: grid;
	grid-template-columns: 50% 50%;
}
.uncover__slices--vertical-double {
	display: grid;
	grid-template-rows: 50% 50%;
}
.uncover__slice {
	color: #1f2029;
	background-color: #1f2029;
	flex: 1;
}
.hover-portfolio-box{
	cursor: url('../img/more.png') 17 17,pointer;
}
.hover-portfolio-box .scroll-img:after{
	position: absolute;
	background-color: #1f2029;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '';
	opacity: 0;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.hover-portfolio-box:hover .scroll-img:after{
	opacity: 0.7;
}
.hover-portfolio-box p{
	position: absolute;
	bottom: 15px;
	left: 30px;
	color: #ebebeb;
	font-size: 16px;
	line-height: 16px;
	font-weight: 300;
	font-style: italic;
	opacity: 0;
	-webkit-writing-mode: vertical-lr;
	writing-mode: vertical-lr;
	-webkit-transform: translateX(20px);
	transform: translateX(20px);
	cursor: pointer;
	letter-spacing: 3px;
	margin: 0;
	padding: 0;
	z-index: 3;
	cursor: url('../img/more.png') 17 17,pointer;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.hover-portfolio-box:hover p{
	opacity: 1;
	-webkit-transform: translateX(0);
	transform: translateX(0);
}
.hover-portfolio-box h4{
	position: absolute;
	bottom: 15px;
	right: 30px;
	color: #fff;
	line-height: 1;
	opacity: 0;
	-webkit-transform: translateY(-20px);
	transform: translateY(-20px);
	cursor: pointer;
	margin: 0;
	padding: 0;
	z-index: 4;
	cursor: url('../img/more.png') 17 17,pointer;
	letter-spacing: 2px;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.hover-portfolio-box:hover h4{
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

/* #Portfolio Masonry
================================================== */

#projects-grid, #profile-grid {
	position: relative;
	text-align: center;
	margin: 0 auto;
	width: 65%;
	min-width: 360px;
	margin-bottom: 5rem;
}
#projects-grid:after, #profile-grid::after {
  content: '';
  display: block;
  clear: both;
}

.portfolio-box{
	position: relative;
	float: left;
	display: inline-block;
	box-sizing: border-box;
	overflow: hidden;
	width: calc(60%);
	margin: 25px;
	margin-left: calc(15% + 32px);
	-webkit-transform: translate3d(1px,0,0);
	transform: translate3d(1px,0,0);
	cursor: pointer;
	cursor: url('../img/more.png') 17 17,pointer;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}
.nfp-vid {
	width: 100%;
	padding-top: 100%;
    border-radius: 12px;

}
.nfp-vid video, .nfp-vid img {
	position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
}

.nfp-vid-modal {
    border-radius: 12px;
	& video {
    	border-radius: 12px;
	}
}
#profile-grid .portfolio-box {
	position: relative;
	float: left;
	display: inline-block;
	box-sizing: border-box;
	overflow: hidden;
	width: calc(20% - 20px);
	margin: 10px;
	margin-left: 9px;
	-webkit-transform: translate3d(1px,0,0);
	transform: translate3d(1px,0,0);
	cursor: pointer;
	cursor: url('../img/more.png') 17 17,pointer;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}
@media (max-width: 900px) {
	#profile-grid .portfolio-box {
		width: calc(50% - 20px);
	}
}
@media (max-width: 575px) {
	#profile-grid .portfolio-box {
		width: calc(100% - 20px);
	}
}
.portfolio-box p {
	position: absolute;
	bottom: 15px;
	left: 15px;
	color: #ebebeb;
	font-size: 16px;
	line-height: 16px;
	font-weight: 300;
	font-style: italic;
	opacity: 0;
	-webkit-writing-mode: vertical-lr;
	writing-mode: vertical-lr;
	-webkit-transform: translateX(20px);
	transform: translateX(20px);
	cursor: pointer;
	letter-spacing: 3px;
	margin: 0;
	padding: 0;
	z-index: 3;
	cursor: url('../img/more.png') 17 17,pointer;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}
.portfolio-box p img {
	width: 14px;
}
.portfolio-box h4 img {
	width: 28px;
}
.portfolio-box:hover p {
	opacity: 1;
	-webkit-transform: translateX(0);
	transform: translateX(0);
}
.portfolio-box h4 {
	position: absolute;
	bottom: 10px;
	right: 12px;
	color: #fff;
	font-weight: 300;
	opacity: 0;
	-webkit-transform: translateY(-20px);
	transform: translateY(-20px);
	cursor: pointer;
	margin: 0;
	padding: 0;
	z-index: 4;
	letter-spacing: 2px;
	cursor: url('../img/more.png') 17 17,pointer;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.portfolio-box:hover h4 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.portfolio-box .portfolio-mask{
	position: absolute;
	top: 0;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(31,32,41,0);
	z-index: 1;
	cursor: pointer;
	cursor: url('../img/more.png') 17 17,pointer;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}
.portfolio-box:hover .portfolio-mask{
	background-color: rgba(31,32,41,0.7);
}
.portfolio-box img{
	width: 100%;
	height: auto;
	display: block;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}
.portfolio-box:hover img{
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

#portfolio-filter {
	position:relative;
	text-align: center;
	z-index:20;
	width:100%;
}
#filter {
	position:relative;
	display: block;
	padding: 0;
	margin: 0;
}
#filter li {
	display: inline-block;
	margin-right: 15px;
	padding: 0;
	float: center;
}
#filter:after {
  content: '';
  display: block;
  clear: both;
}
#filter li a {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	outline: none;
	color: rgba(200,200,200,.5);
	font-weight: 700;
	font-style: italic;
	letter-spacing: 1px;
	font-size: 15px;
	line-height:22px;
	text-decoration: none;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}
#filter li a:hover {
	color:#fff;
}
#filter li a:before {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: -9px;
	height: 2px;
	content: '';
	z-index: -1;
	background-color: #fff;
	opacity: 0;
	visibility: hidden;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
#filter li a.current:before {
	bottom: -4px;
	opacity: 1;
	visibility: visible;
}
#filter li a.current {
	color:#fff;
}
#filter li a:hover:before {
	bottom: -4px;
	opacity: 1;
	visibility: visible;
}
.isotope-item {
    z-index: 2;
}
.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}
.isotope, .isotope .isotope-item {
    -webkit-transition-duration: 0.6s;
       -moz-transition-duration: 0.6s;
            transition-duration: 0.6s;
}
.isotope {
    -webkit-transition-property: height, width;
       -moz-transition-property: height, width;
            transition-property: height, width;
}
.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
       -moz-transition-property:    -moz-transform, opacity;
        -ms-transition-property:     -ms-transform, opacity;
         -o-transition-property:         top, left, opacity;
            transition-property:         transform, opacity;
}

/* #Project video with cover image
================================================== */

.video-section {
	position: relative;
	width:100%;
	margin:0 auto;
}
.video-wrapper, figure.vimeo, figure.youtube {
	margin:0;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 0;
	height: 0;
	overflow: hidden;
}	
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}	
figure.youtube a img, figure.vimeo a img {
	position: absolute;
	top: 0;
	left: 0;
	width: auto;
	height: 100%;
	max-width:none;
}	
figure.vimeo a:after, figure.youtube a:after {
	content:"";
	width:60px;
	height:60px;
	background: #fff;
	z-index:9;
	position:absolute;
	top:50%;
	left:50%;
	margin:-30px 0 0 -30px;
	border-radius:50%;
	box-shadow: 0px 0px 0px 10px rgba(0,0,0,0.15);
	cursor: url('../img/play.png') 6 8,pointer;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear; 
}
figure.vimeo:hover a:after, figure.youtube:hover a:after {
	box-shadow: 0px 0px 0px 1000px rgba(0,0,0,0.1);
}
figure.vimeo:hover a:after, figure.youtube:hover a:after{
	opacity: 0;
}
figure.vimeo a:before, figure.youtube a:before {
	border-left: 8px solid #212121;
}
figure.vimeo a:before, figure.youtube a:before {
	content:"";
	width: 0;
	height: 0;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	z-index:10;
	position:absolute;
	top:50%;
	left:50%;
	margin-left: -3px;
	margin-top: -5px;
	display:block;
	cursor: url('../img/play.png') 6 8,pointer;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear; 
}
figure.vimeo:hover a:before, figure.youtube:hover a:before {
	border-color: transparent;
}	
figure.vimeo a:hover img, figure.youtube a:hover img {
	-webkit-transition: all 0.4s;
	transition: all 0.4s;
}	
figure.vimeo a img, figure.youtube a img {
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear; 
	cursor: url('../img/play.png') 6 8,pointer;
}


/* #Studio page
================================================== */

.img-mask{
	-webkit-mask-image: url('../img/mask-image.png');
	mask-image: url('../img/mask-image.png');
	-webkit-mask-position: center center;
	mask-position: center center;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-size: cover;
	mask-size: cover;
}
.studio-list {
	padding: 0;
	margin: 0;
	list-style: none;
}
.studio-list li {
	position: relative;
	padding: 0;
	margin: 0;
	padding-left: 40px;
	margin-bottom: 8px;
	list-style: none;
}
.studio-list li:last-child {
	margin-bottom: 0;
}
.studio-list li:before{
	position: absolute;
	content: '';
	top:50%;
	left: 0;
	width: 20px;
	height: 1px;
	background-color: #616481;
}
.studio-list li p{
	padding: 0;
	margin: 0;
}

.logo-box {
	position: relative;
	width: 100%;
	display: block;
	margin: 0 auto;
	text-align: center;
	padding: 70px 0;
	border: 1px solid rgba(200, 200, 200, .2);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.logo-box:hover {
	border: 1px solid rgba(200, 200, 200, .6);
}
.logo-box img {
	height: 45px;
	width: auto;
	display: inline-block;
	margin: 0 auto;
	text-align: center;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.logo-box img:hover {
	opacity: 0.4;
}
.blockquote{
	position: relative;
	padding-left: 70px;
	display: block;
}
.blockquote:before{
	position: absolute;
	content: '”';
	top: 0;
	left: 0;
	font-family: 'Muli', sans-serif;
	font-size: 120px;
	line-height: 0.9;
	-webkit-text-stroke: 2px #616481;
	text-stroke: 2px #616481;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
}

/* #Blog page
================================================== */
 
.case-study-wrapper.vertical-blog{
	list-style: none;
	padding: 0;
	margin: 0;
} 
.case-study-wrapper.vertical-blog li{
	list-style: none;
	padding: 0;
	margin: 0;
} 
.case-study-wrapper.vertical-blog li a{
	display: inline-block;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear; 
}
.case-study-wrapper.vertical-blog .case-study-name{
	opacity: 0.4;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear; 
}
.case-study-wrapper.vertical-blog .case-study-name.active{
	opacity: 1;
}
.case-study-wrapper.vertical-blog li a:hover{
	opacity: 1; 
}
.case-study-wrapper.vertical-blog li p{
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.7;
	color: #c4c3ca;
	padding-left: 0;
}
.case-study-wrapper.vertical-blog li p:before{
	display: none;
}
.case-study-wrapper.vertical-blog .case-study-name.active h4{
	opacity: 1;
}
.case-study-images li .img-hero-background.blog-back-image{
	position: fixed;
	width: 500px;
	height: 500px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	box-shadow: inset 0 0 50px #1f2029;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear; 
}
.case-study-images li.show .img-hero-background.blog-back-image {
	opacity: 0.3;
}

.go-to-post {
	position: absolute;
	right: 30px;
	bottom: 30px;
	width: 40px;
	height: 10px;
	z-index: 2;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: 40px 10px;
	cursor: pointer;
	background-image: url('../img/arrow-right.png');
	transform: translateX(0);
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear; 
}
.go-to-post:hover {
	transform: translateX(5px);
}

/* #Blog post
================================================== */
 
.post-box{
	position: relative;
	width: 100%;
	display: block;
	padding: 0;
	padding-bottom: 2.5rem;
	border-radius: 0.35rem;
}
.rounded{
	border-radius: 0.35rem;
}
.sidebar-box p,
.post-comm-box p,
.post-box p{
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.7;
	color: #c4c3ca;
	padding-left: 0;
	margin-left: 0;
}
.sidebar-box p:before,
.post-comm-box p:before,
.post-box p:before{
	display: none;
}
.drop-shadow{
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.06);
}
.post-box img.blog-home-img{
	width: 100%;
	height: auto;
	display: block;
	border-radius: 0;
	margin-right: 0;
}
.post-box .padding-in{
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}
.post-box .blockquote:before{
	display: none;
}
.post-box .blockquote{
	padding-top: 1.4rem;
	padding-bottom: 1.4rem;
	padding-left: 1.4rem;
	padding-right: 0;
}
.post-box .blockquote-footer::before {
	padding-right:10px;
}
.post-box .blockquote.blockquote-reverse{
	border-left: none;
	padding-right: 1.4rem;
	padding-left: 0;
}
.post-box .blockquote-reverse .blockquote-footer::before {
	padding-right:0;
}
.post-box .blockquote-reverse .blockquote-footer::after {
	padding-left:10px;
}
.post-box .blockquote{
	border-left: 0.4rem solid #999;
}
.post-box .blockquote-reverse{
	border-right: 0.4rem solid #999;
}
.separator-wrap{
	width:100%;
	min-height: 1px;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
	flex-wrap: no-wrap;
	webkit-box-align: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
}
.separator-wrap p{
	margin-bottom: 0;
	padding-left: 1rem;
	padding-right: 1rem;
}
.separator{
	position: relative;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	min-width: 10%;
}
.separator-line{
	position: relative;
	height: 1px;
	display: block;
	top: 1px;
	width: 100%;
	border-top: 1px solid #666;
}
.opacity-40{
	opacity: 0.4;
}
.separator-line.med{
	border-top-width: 3px;
}
.separator-line.big{
	border-top-width: 8px;
	border-radius:2px;
}
.separator-line.dashed{
	border-top-style: dashed;
	outline: none;
} 
.post-box .btn-primary{
	background-color: #333;
	cursor: pointer;
	border: none;
	outline: none;
	padding: 7px 12px;
}
.post-box .btn-primary:focus,
.post-box .btn-primary:active {
	border:none;
	outline:none;
	box-shadow: none;
}
.post-box .btn-primary span{
	position: relative;
	display: block;
	cursor: pointer;
    -webkit-transition: all 250ms linear;
    -moz-transition: all 250ms linear;
    -o-transition: all 250ms linear;
    -ms-transition: all 250ms linear;
    transition: all 250ms linear;
}
.post-box .btn-primary:hover{
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
	background-color: #444;
}
.author-wrap{
	position: relative;
	width: 100%;
	display: block;
}
.author-wrap p{
	display: inline-block;
	font-size: 0.84rem;
}
.author-wrap p a{
	color: #ccc;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}
.author-wrap p a:hover{
	color: #fff;
}
.author-wrap img{
	width: 60px;
	height: 60px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 10px;
}
.post-comm-box{
	position: relative;
	width: 100%;
	display: block;
	padding: 2.5rem;
}
.post-comm-box img{
	width: 40px;
	height: 40px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 20px;
}
.post-comm-box h6{
	display: inline-block;
}
.post-comm-box h6 small{
	text-transform: none;
	font-weight:400;
	font-size: .7rem;
	font-style: italic;
	margin-left: 10px;
}
.post-comm-box a{
	color: #fff;
	cursor: pointer;
	background-color: #333;
	border: none;
	outline: none;
	box-shadow: none;
	padding: 7px 12px;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}
.post-comm-box a:focus,
.post-comm-box a:active {
	border:none;
	outline:none;
	box-shadow: none;
	background-color: #333;
}
.post-comm-box a:hover{
	background-color: #444;
}
.subscribe-box {
	position: relative;
}
.subscribe-box .subscribe-1{
	position: absolute;
	top:0;
	right:0;
	z-index: 1;
}
.subscribe-box .subscribe-1{
	color: #fff;
	cursor: pointer;
	background-color: #333;
	border: none;
	outline: none;
	box-shadow: none;
	padding: 20px 15px;
	font-weight: 400;
	letter-spacing: 1px;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}
.subscribe-box .subscribe-1 button:focus,
.subscribe-box .subscribe-1 button:active {
	border:none;
	outline:none;
	box-shadow: none;
	background-color: #333;
}
.sidebar-box{
	position: relative;
	width: 100%;
	display: block;
	padding: 2.5rem;
}
.sidebar-box h6{
}
.list-style {
	margin: 0;
	list-style: none;
}
.list-style li{
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 0.8rem;
	line-height: 1.1rem;
}
.list-style li:first-child{
	margin-top: 0;
}
.list-style li:last-child{
	margin-bottom: 0;
}
.list-style.square li:before,
.list-style.circle-o li:before,
.list-style.circle li:before{
	font-family:'FontAwesome';
	font-size: 0.5rem;
	margin-right: 15px;
	display:inline-block;
	line-height: 1.1rem;
	height: 1.1rem;
	vertical-align: middle;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale
}
.list-style.circle li:before{
	content: '\f111';
}
.list-style.circle-o li:before{
	content: '\f10c';
}
.list-style.square li:before{
	content: '\f0c8';
}
.list-style li i{
	font-size: 0.8rem;
	line-height: 1.1rem;
	height: 1.1rem;
	width:20px;
	text-align:left;
	margin-right: 10px;
}
.list-style a.btn-link {
	background-color: transparent;
	color: #fff;
	-webkit-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-o-transition: all 200ms linear;
	-ms-transition: all 200ms linear;
	transition: all 200ms linear;
}
.list-style a.btn-link:hover{
	text-decoration: none;
	background-color: transparent;
	box-shadow: none;
}
.tags-link{
	color: #fff;
	cursor: pointer;
	background-color: #333;
	border: none;
	outline: none;
	display: inline-block;
	box-shadow: none;
	padding: 7px 12px;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}
.tags-link:hover{
	color: #fff;
	background-color: #555;
}
.tags-link:focus,
.tags-link:active {
	border:none;
	outline:none;
	box-shadow: none;
	color: #fff;
	background-color: #333;
}


/* #Contact
================================================== */

.ajax-form input,
.ajax-form textarea{
	position:relative;
	width: 100%;
	padding: 20px 20px;
	font-weight: 400;
	letter-spacing: 1px;
	font-size: 15px;
	line-height: 1.7;
	background-color: #14151a;
	color: #fff;
	border:none;
	text-align: left;
	cursor: text;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear;
}
.ajax-form textarea{
	height: 170px;
}
.ajax-form textarea:active,
.ajax-form input:active {	
	color: #ccc;
}
.ajax-form textarea:active,
.ajax-form input:hover {	
	color: #ccc;
}
.ajax-form textarea:focus,
.ajax-form input:focus {	 
    outline: none !important;
}
.ajax-form input:-ms-input-placeholder  {
	font-size: 15px;
	letter-spacing:1px;
	color: rgba( 255,255,255, 0.4);
	font-weight: 700;
}
.ajax-form input::-moz-placeholder  {
	font-size: 15px;
	letter-spacing:1px;
	color: rgba( 255,255,255, 0.4);
	font-weight: 700;
}
.ajax-form input:-moz-placeholder  {
	font-size: 15px;
	letter-spacing:1px;
	color: rgba( 255,255,255, 0.4);
	font-weight: 700;
}
.ajax-form input::-webkit-input-placeholder  {
	font-size: 15px;
	letter-spacing:1px;
	color: rgba( 255,255,255, 0.4);
	font-weight: 700;
}
.ajax-form textarea:-ms-input-placeholder  {
	font-size: 15px;
	letter-spacing:1px;
	color: rgba( 255,255,255, 0.4);
	font-weight: 700;
}
.ajax-form textarea::-moz-placeholder  {
	font-size: 15px;
	letter-spacing:1px;
	color: rgba( 255,255,255, 0.4);
	font-weight: 700;
}
.ajax-form textarea:-moz-placeholder  {
	font-size: 15px;
	letter-spacing:1px;
	color: rgba( 255,255,255, 0.4);
	font-weight: 700;
}
.ajax-form textarea::-webkit-input-placeholder  {
	font-size: 15px;
	letter-spacing:1px;
	color: rgba( 255,255,255, 0.4);
	font-weight: 700;
}
.ajax-form button {
	color: rgba(255,255,255,.5);
	cursor: pointer;
	font-size: 15px;
	padding: 12px 25px;
	letter-spacing: 2px;
	background-color: #14151a;
	border:none;
	outline:none;
	margin: 0 auto;
	text-align: center;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear;
}
.ajax-form button:focus,
.ajax-form button:active {
	border:none;
	outline:none;
}
.ajax-form button span{
	position: relative;
	display: block;
	cursor: pointer;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear;
}
.ajax-form button:hover{
	background-color: #090909;
}

.filter-select .nice-select {
	padding-top: 14px;
	padding-bottom: 14px;
	font-size: 13px;
	line-height: 1.4;
	border: solid 3px #dbdddc;
}
.filter-select .nice-select:after {
    right: 20px;
}
.filter-select .nice-select .list {
	border: solid 3px #dbdddc;
	width: calc(100% + 6px);
	margin-left: -3px;
}
.filter-select .nice-select .option {
    min-height: auto;
	font-size: 13px;
	padding-top: 13px;
	padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
	text-transform: lowercase;
} 
.nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color: #14151a;
	border: none;
	box-sizing: border-box;
	clear: both;
	cursor: pointer;
	display: block;
	color: rgba( 255,255,255, 0.4);
	float: left;
	overflow: hidden;
	font-family: inherit;
	letter-spacing: 1px;
	font-size: 15px;
	line-height: 1.7;
	font-weight: 700;
	outline: none;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 30px;
	position: relative;
	text-align: left !important;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
	white-space: nowrap;
	width: auto; 
}
.nice-select.open {
	overflow: visible;
}
.nice-select:hover {
    border: none; 
}
.nice-select:after {
    border-bottom: 2px solid rgba(255,255,255,.4);
    border-right: 2px solid rgba(255,255,255,.4);
    content: '';
    display: block;
    height: 10px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute;
    right: 25px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
        -ms-transform-origin: 66% 66%;
            transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 10px; 
}
.nice-select.open:after {
    -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
            transform: rotate(-135deg); 
}
.nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0); 
}
.nice-select.disabled {
    border-color: #ededed;
    color: #ccc;
    pointer-events: none; 
}
.nice-select.disabled:after {
      border-color: #cccccc; 
}
.nice-select.wide {
	width: 100%; 
}
.nice-select.wide .list {
    left: 0 !important;
    right: 0 !important; 
}
.nice-select.right {
    float: right; 
}
.nice-select.right .list {
    left: auto;
    right: 0; 
}
.nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; 
}
.nice-select.small:after {
    height: 4px;
    width: 4px; 
}
.nice-select.small .option {
    line-height: 34px;
    min-height: 34px; 
}
.nice-select .list {
	background-color: #09090c;
    border-radius: 0;
    box-sizing: border-box;
    margin-top: 1px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
	width: 100%;
	box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.4);
	left: 0;
	color: #ccc;
    top: 100%;
    -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
            transform-origin: 50% 0;
    -webkit-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
            transform: translateY(-15px);
    -webkit-transition: all 0.3s linear, opacity 0.15s ease-out;
    transition: all 0.3s linear, opacity 0.15s ease-out;
    z-index: 9; 
}
.nice-select .list:hover .option:not(:hover) {
}
.nice-select .option {
    cursor: pointer;
    font-weight: 400;
    list-style: none;
	font-size: 14px;
	letter-spacing: 1px;
	padding-top: 11px;
	padding-bottom: 11px;
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; 
} 
.nice-select .option.focus, .nice-select .option.selected.focus {
    background-color: #2a2c37;
}
.nice-select .option:hover {
    color: #fff;
    background-color: #353746;
}
.nice-select .option.selected {
    font-weight: 400 !important;
}
.nice-select .option.disabled {
    background-color: transparent;
    color: #ccc;
    cursor: default; 
}
.no-csspointerevents .nice-select .list {
	display: none; 
}
.no-csspointerevents .nice-select.open .list {
	display: block; 
}

.contact-map  {
	background-size: cover;
	background-position:center center;
	background-repeat:no-repeat;
	background-image: url('../img/map.jpg');
}
.contact-link{
	font-size: 15px;
	line-height: 1.7;
	letter-spacing: 2px;
	font-weight: 400;
	color: #c4c3ca;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear;
}
.contact-link:hover{
	text-decoration: none;
	color: #fff;
}

/* #Social links
================================================== */

.social-fixed {
	position: fixed;
	top: auto;
	bottom: 40px;
	left: 30px;
	z-index: 94;
	-webkit-writing-mode: vertical-lr;
	writing-mode: vertical-lr;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear; 
}
.social-fixed.bottom-name {
	bottom: 140px;
}
.social-fixed a{
	position: relative;
	text-align: center;
	margin-top: 7px;
	/* margin-bottom: 7px; */
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1px;
	color: #fff;
	display: inline-block;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}
.social-fixed a:hover{
	opacity: 0.5;
}
.copyr {
	position: fixed;
	bottom: 40px;
	right: 30px;
	z-index: 94;
	/*-webkit-writing-mode: vertical-lr;*/
	writing-mode: vertical-lr;
	font-family: 'Yanone Kaffeesatz', sans-serif;
	font-size: 14px;
	font-weight: 200;
	letter-spacing: 1px;
	color: #fff;
	transform: rotate(180deg);
	text-orientation: sideways;
}
.copyr.bottom-name {
	bottom: 140px;
}
.copyr a{
	color: #fff;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.copyr a:hover{
	opacity: 0.5;
}
.footer a{
	position: relative;
	display: inline-block;
}
.footer-lines{
	position: relative;
}
.footer-lines:after,
.footer-lines:before{
	position: absolute;
	width: 2px;
	height: 25px;
	content: '';
	z-index: -1;
	left: 50%;
	margin-left: -1px;
	background-color: #4b4d64;
}
.footer-lines:after{
	top: -50px;
}
.footer-lines:before{
	bottom: -50px;
}
.shadow-on-footer{
	position: absolute;
	top: calc(50% - 7vw);
	left: -50%;
	width: 200%;
	text-align: center;
	font-family: 'Muli', sans-serif;
	font-size: 14vw;
	line-height: 1;
	color: rgba(200,200,200,.1);
	background: linear-gradient(rgba(144, 144, 144,0.30), rgba(75, 77, 100,0));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 900;
	z-index: 1;
}

/* #Media
================================================== */

@media (max-width: 1500px) { 
}

@media (max-width: 1300px) {
}

@media (max-width: 1300px) { 
	.swiper-slide{
		width: 40%;
	}
	.swiper-slide a{
		margin: 0;
	}
	.swiper-slide h1{
		font-size: 40px;
		letter-spacing: 1px;
	}

	.swiper-slide h2{
		font-size: 40px;
		letter-spacing: 1px;
	}
	.case-study-images li .hero-number-fixed.bottom-name{
		bottom: 140px;
	}
	.case-study-images li .hero-number.bottom-name{
		bottom: 140px;
	}
	.social-fixed.bottom-name {
		bottom: 110px;
	}
	.copyr.bottom-name {
		bottom: 110px;
	}
	.swiper-container-horizontal > .swiper-scrollbar.bottom-name {
		bottom: 110px;
	}
	.swiper-slide.bottom-name a{
		width: 100%;
		display: block;
	}
	.swiper-slide.bottom-name h1{
		padding: 20px 0;
		width: 100%;
		display: block;
	}
	.case-study-name.active h1:before{
		height: 10px;
	}
	.swiper-container-horizontal > .swiper-scrollbar {
		bottom: 25px;
	}
	.case-study-images li .hero-number,
	.case-study-images li .hero-number-fixed{
		bottom: 50px;
	}
	.social-fixed {
		bottom: 15px;
		left: 17px;
	}
	.copyr {
		bottom: 20px;
		right: 17px;
	}
	.scroll-to-top{
		right: 22px;
		bottom: 180px;
	}
	.scroll-to-top:hover{
		bottom: 182px;
	}
	.page-title h1{
		font-size: 40px;
	}
	.page-title p{
		font-size: 22px;
	}
	.padding-page-top {
		padding-top: 170px;
	}
	.padding-top-bottom {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.padding-top {
		padding-top: 80px;
	}
	.padding-bottom {
		padding-bottom: 80px;
	}
	.padding-top-bottom-big {
		padding-top: 110px;
		padding-bottom: 110px;
	}
	.padding-top-big {
		padding-top: 110px;
	}
	.padding-bottom-big {
		padding-bottom: 0;
	}
	.padding-bottom-big-x {
		padding-bottom: 70px;
	}
	.case-study-images li .img-hero-background.blog-back-image{
		width: 300px;
		height: 300px;
	}
}

@media (max-width: 991px) { 
	body.nav-active .curent-page-name-shadow {
		margin-top: -10px;
	}
	.swiper-slide{
		width: 50%;
	}
	.swiper-slide h1{
		font-size: 30px;
	}
	.swiper-slide h2{
		font-size: 40px;
	}
	.case-study-images li .img-hero-background-over{
		width: calc(100% - 100px);
		height: calc(100% - 200px);
		top: 100px;
		left: 50px;
	}
	.nav__list-item a:hover:after,
	.nav__list-item.active-nav a:after{ 
		height: 10px;
	}
	.swiper-container-horizontal > .swiper-scrollbar {
		left: 70px;
		right: 70px;
		width: calc(100% - 140px);
	}
	.padding-page-top {
		padding-top: 290px;
	}
	.padding-page-top-x {
		padding-top: 70px;
	}
	.padding-top-bottom {
		padding-top: px;
		padding-bottom: px;
	}
	.padding-top {
		padding-top: 60px;
	}
	.padding-bottom {
		padding-bottom: 260px;
	}
	.padding-bottom-x {
		padding-bottom: 220px;
	}
	.padding-bottom-xx {
		padding-bottom: 250px;
	}
	.padding-top-bottom-big {
		padding-top: 90px;
		padding-bottom: 90px;
	}
	.padding-top-big {
		padding-top: 90px;
	}
	.padding-bottom-big {
		padding-bottom: px;
	}
	.shadow-title{
		display: none;
	}
	.page-title h1{
		font-size: 40px;
	}
	.page-title p{
		font-size: 20px;
	}
	.logo-box {
		padding: 30px 0;
	}
	.case-study-images li .img-hero-background.blog-back-image{
		width: 200px;
		height: 200px;
	}	
}

@media (max-width: 767px) {
	h3, .h3 {
		font-size: 2rem;
	}
	h4, .h4 {
		font-size: 1.4rem;
	}
	.swiper-slide h1{
		font-size: 34px;
	}
	.curent-page-name-shadow {
		display: none;
	}
	.page-title h1{
		font-size: 40px;
		line-height: 1.1em;
	}
	.portfolio-box{
		width: calc(100% - 70px);
		margin: 35px;
		margin-left: 35px;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}
}

@media (max-width: 575px) {
	.swiper-slide{
		width: auto;
	}
	.swiper-slide a{
		margin: 0 25px;
	}
	.page-title h1{
		font-size: 32px;
	}
	.swiper-slide.bottom-name h1{
		padding: 20px 30px;
		-webkit-text-stroke: 1px #fff;
		text-stroke: 1px #fff;
	}
	.page-title p{
		font-size: 17px;
	}	
	.lead {
		font-size: 1.05rem;
	}
	#filter li a {
		letter-spacing: 0;
		font-size: 14px;
	}
}
.myButton2 {
	background-color:#ffffff;
	border-radius:14px;
	border:0px solid #ffffff;
	display:inline-block;
	cursor:pointer;
	color:#000000;
	font-family:Arial;
	font-size:15px;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px #2f6627;
}
.myButton2:hover {
	background-color:#000000;
}
.myButton2:active {
	position:relative;
	top:1px;
}











