.crop img {
	width: 300px;
	height: auto;
}

#mint-NFP, #LazyMint-NFP, #finalize-NFP {
	margin-top: 1.5em;
}

.btn.rarible {

}

#PreviewNFT p {
	font-weight: bold;
	padding-top: 0.75em;
	margin-bottom:-0.5em;
} 