body.loading {
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: relative;

	.loader {
		position: absolute;
		top: 0; bottom: 0;
		left:0; right: 0;
		z-index: 1000;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.6);
		content: 'Loading...';
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2em;
		font-weight: bold;
	}
}