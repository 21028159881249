#connected {
	border: solid 1px #fff;
	border-radius: 8px;
	vertical-align: middle;

	#avatar-address {
		padding-right: 0.3em;
		font-size: 0.9em;
	}

	#identicon {
		div {
			border: solid 1px white;
			margin-bottom: -0.35em !important;
		}
	}
}

.desk-menu {
	display: none;
}

@include media-breakpoint-up( lg ) {
	.menu-icon {
		display: none;
	}
	
	.desk-menu {
		position: relative;
		display: block;
		font-family: "Yanone Kaffeesatz", sans-serif;
		padding: 0;
		margin: -0.25em 0 0;
		font-size: 1.35em;
		font-weight: bold;
		text-transform: uppercase;

		a {
			color: #FFF;
			padding: 0 1em;
		}

		.desk-menu-item {
			display: inline-block;
		}

		.sub-links {
			display: none;
			background: rgba(0,0,0,0.3);
			position: absolute;
			text-align: right; 
			padding: 0;
			margin: 0;
			list-style-type: none;
			width: 15em;
			padding: 0.25em;
			right: 0;
			a {
				padding: 1.5em 1em;
			}
		}
	}
}