
.post-lead {
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    color:#fafafa;
    line-height: 1.75;
    margin-top: 1.35em;
  }
  p:first-child {
    margin-top:   0em;
  }
}
.tiers {
  h4 {
    text-align: center;
    margin:2em 0 .5em;
  }
  .nfp-vid-modal {
    border:solid 2px #313131;
    background: #000;
    padding: 0.75em;
    line-height: 0;
    border-radius: 22px;
    box-shadow: 0 0 37px rgba(78,78,78 , 0.76);
  }
  .end-block {
    padding-bottom: 3em;
  }
}
.containerCW{
height:100%;
position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.btnCW {
    margin-top: calc();
    position: relative;
    display: inline-block;
    width: 155px;
    height: 50px;
    font-size: 1em;
    font-weight: bold;
    line-height: 64px;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    cursor: pointer;
    text-decoration:none;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight:900;
    font-size: 21px;
    letter-spacing: 0.045em;
    transform: scale(0.9);
    transition: transform .3s;
}

.btnCW:hover {
    /*transform: scale(1);*/

}

.btnCW svg {
    position: absolute;
    top: 0;
    left: 0;
}

.btnCW svg rect {
    /*stroke: #EC0033;*/
    stroke-width: 2;
    stroke-dasharray: 313, 0;
    stroke-dashoffset: 0;
    -webkit-transition: all 600ms ease;
    transition: all 600ms ease;
}

.btnCW span{
  background: rgb(255,255,255);
  background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(230,230,230,1) 100%);
  background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(230,230,230,1) 100%);
  background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(230,230,230,1) 100%);
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8282', endColorstr='#e178ed',GradientType=1 );*/
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fffffff',GradientType=1 );
  
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btnCW:hover svg rect {
    stroke-dasharray: 97, 433;
    stroke-dashoffset: 362;
}

/* BOOTSTRAP OVERRIDES */
.btn {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: bold;
}

.btn-smaller {
    border-radius: 12px;
    padding: 0.35rem 0.75rem 0.2rem;
}

/*ZEMI UTILS*/
.vp-100-h {
    min-height: 100vh;
}

.mm-login a {
    display: block;
    margin: -0.25rem 1.25rem 0 0;
}
.mm-logged {
  margin-top: -0.35rem;
} 

.mm-logged button {
  appearance: none;
  border: none;
  background: transparent;
  color: #FFF;
}

.mm-logged .dropdown-menu {
  background: transparent;
}
.mm-logged .dropdown-menu button {
  background: transparent;
  text-align: center;
    color: #FFF;
  border-bottom: 1px solid #fff;
  width: 140px;
  margin-left:20px;
}

.mm-logged .dropdown-item {
  padding: 0.15rem 0;
}

.header-spacing-top {
  margin-top: 150px;
  margin-bottom: 75px;
}

/* create NFP styles */
.create h2 {
    font-weight: bold;
    padding-bottom: 0;
    padding-top: 2rem;
}
.create h3 {
    font-weight: normal;
    font-size: 2rem;
}
.nfp-tweets {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.unlock-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
}
.nfp-tweets > * {
}

.nfp-tweets input, input {
    text-align: center;
    font-size: 12px;
    padding: 0.25rem;
}
.nfp-tweets input:focus, input:focus {
  outline: none;
}

.nfp-tweets input, .nfp-tweets .ta-wrapper, input {
    width: 80%;
    /* max-width:600px; */
    margin-bottom: 1rem;
}
@media (max-width: 991px) {
  .nfp-tweets input, .nfp-tweets .ta-wrapper, input {
    width:100%;
  }
}
.nfp-tweets textarea {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    width: 100%;
}

.nfp-tweets .ta-wrapper {
    position: relative;
}
.nfp-tweets .ta-wrapper .btn-copy {
    background: url(../img/btn-copy.svg);
    background-size: contain;
    background-repeat: none;
    display: block;
    content: " ";
    position: absolute;
    top: 1.5rem;
    right: 0.5rem;
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
}

.nfp-tweets .copy {
    border: none;
    border-radius: 12px;
}

.profile .nfp-tweets .copy {
  border: solid 1px #333;
}

.profile .nfp-tweets .ta-wrapper {
    width: 80%;
    margin-bottom: 0.25rem;
}

.nfp-tweets .paste, .paste {
    background: transparent;
    color: #FFF;
    border: none;
    border-bottom:solid 2px #FFF
}

.summary {
    text-align: center;
}

.tw-user {
    background: #FFF;
    padding: 1.2rem;
    border-radius: 12px;
}

.tw-user .avatar {
    position: relative;
}

.tw-user .avatar::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    width: 100%;
    height: 100%;
    background: url(../img/nfp-cube.svg) top center;
    background-size: contain;
}

.tw-user h5 {
    color: #000;
}

.tw-user p {
  margin: 0.15rem;
  color: var(--dark);
}

/*MOBILE STYLES*/
@media (max-width: 520px) {
  h5 {
    font-size: 1.1rem;
  }
  .nfp-tweets textarea {
    font-size: 0.8em;
  }
}

/*HOMEPAGE VIDEO*/
* {
  box-sizing: border-box;
}
#HpBackground {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  width: 1425px;
}

/*INTERIOR VIDEO*/
* {
  box-sizing: border-box;
}
#InteriorBackground {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  width: 1425px;
}

/** LOADER **/
@keyframes ldio-tbfn8bsmlbc {
0% { transform: scale(1.25) }
100% { transform: scale(1)  }
}
.ldio-tbfn8bsmlbc div {
position: absolute;
width: 34px;
height: 34px;
top: 10.666666666666664px;
left: 10.666666666666664px;
background: #FFF;
animation: ldio-tbfn8bsmlbc 1s cubic-bezier(0,0.5,0.5,1) infinite;
animation-delay: -0.3s;
}
.ldio-tbfn8bsmlbc div:nth-child(2) {
top: 10.666666666666664px;
left: 55.33333333333333px;
background: #FFF;
animation-delay: -0.2s;
}
.ldio-tbfn8bsmlbc div:nth-child(3) {
top: 55.33333333333333px;
left: 10.666666666666664px;
background: #FFF;
animation-delay: 0s;
}
.ldio-tbfn8bsmlbc div:nth-child(4) {
top: 55.33333333333333px;
left: 55.33333333333333px;
background: #FFF;
animation-delay: -0.1s;
}
.loadingio-spinner-cube-t79wht1902s {
width: 30px;
height: 30px;
display: inline-block;
overflow: hidden;
background: none;
}
.ldio-tbfn8bsmlbc {
width: 100%;
height: 100%;
position: relative;
transform: translateZ(0) scale(0.3);
backface-visibility: hidden;
transform-origin: 0 0; /* see note above */
}
.ldio-tbfn8bsmlbc div { box-sizing: content-box; }
/* generated by https://loading.io/ */


/** FAQS accordion **/

.accordion {
  max-width: 550px;
  margin: 3.5rem auto;
} 
.accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
}
.accordion .accordion-item button[aria-expanded=true] {
  border-bottom: 1px solid #eee;
}
.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #ccc;
  font-size: 1.15rem;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}
.accordion button:hover, .accordion button:focus {
  cursor: pointer;
  color: #eee;
}
.accordion button:hover::after, .accordion button:focus::after {
  cursor: pointer;
  color: #eee;
  border: 1px solid #eee;
}
.accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}
.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}
.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}
.accordion button[aria-expanded=true] {
  color: #eee;
}
.accordion button[aria-expanded=true] .icon::after {
  width: 0;
}
.accordion button[aria-expanded=true] + .accordion-content {
  opacity: 1;
  max-height: 70em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content p {
  font-size: 1rem;
  font-weight: 300;
  margin: 2em 0;
}
.accordion .accordion-content a {
  color: #FFF;
}
.accordion .accordion-content a:hover {
  text-decoration: underline;
}


/****************************************
*
* MODALS
*
****************************************/

.modal p {
  color: #000;
}


/** Resize image for NFP Preview **/
/** first crop original image**/
.crop {
  // width: 150px;
  // height: 150px;
  overflow: hidden;
}
/** then rescale it**/
.crop img {
  width: 150px;
  height: 150px;
  // margin: -110px 0 0 -85px;
}

